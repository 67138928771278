@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import 'range-slider';
@import 'snackbar';

html {
  font-size: 10px;
}

body {
  min-height: 100vh;
  @apply ft-typo ft-text-black;
}

.container {
  margin: 0 auto;
  width: 100%;
  padding: 0 1.6rem;
  @screen lg {
    width: 119.2rem;
  }
  @screen xl {
    width: 135.2rem;
  }
  &__inner:not(.new-ui) {
    width: 100%;
    margin: 0 auto;
    @screen xmd {
      width: 87.2rem;
    }
  }
}

.scrollbars-hidden {
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
}
