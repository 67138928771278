.ft-overlay {
  @apply ft-text-black;

  p {
    @apply ft-m-0;
  }

  svg {
    fill: currentColor;
  }
}

.modal-backdrop {
  @apply ft-bg-black-60;

  transition: 0.25s ease-in-out all;
}

.sidebar-backdrop {
  @apply ft-bg-black-60;
}
