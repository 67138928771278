.range-slider {
  .mat-mdc-slider {
    @apply ft-block ft-h-24 ft-mx-12;
    --mdc-slider-handle-shape: 50%;
    --mdc-slider-handle-width: 2rem;
    --mdc-slider-handle-height: 2rem;
    --mdc-slider-active-track-height: 3px;
    --mdc-slider-inactive-track-height: 3px;

    &.mat-primary {
      --mdc-slider-handle-color: theme('colors.controls.700');
      --mdc-slider-focus-handle-color: theme('colors.controls.700');
      --mdc-slider-active-track-color: theme('colors.controls.700');
      --mdc-slider-inactive-track-color: theme('colors.black.12');
      --mdc-slider-with-overlap-handle-outline-color: theme('colors.white.DEFAULT');
    }
    .mdc-slider__track--inactive {
      opacity: 1;
    }
  }
  .mdc-slider__input {
    @apply ft-h-24;
  }
  .mdc-slider .mdc-slider__thumb {
    @apply ft-w-20 ft-h-20;
    left: -10px;
    top: 2px;
  }
}
