@use 'overlay';

@import '@angular/cdk/overlay-prebuilt.css';

.mat-snack-bar-container.ecom-ui-snack-bar {
  margin: 0 !important;
}

.link {
  @apply ft-block ft-text-controls ft-typo-14-regular;

  &:hover {
    @apply ft-cursor-pointer;
    @apply ft-text-controls;
  }
}

.link-container {
  &:hover {
    @apply ft-bg-additional-100 ft-rounded-8;
  }
}

// OSM markers styles start
.osm-marker-tail {
  @apply ft-absolute;
  @apply ft-bottom-[-0.5rem] ft-content-[''];
  @apply ft-border-t-[0.6rem];
  @apply ft-border-l-8 ft-border-l-transparent;
  @apply ft-border-r-8 ft-border-r-transparent;
}
.osm-marker-container {
  @apply ft-border-0;
}
.osm-marker-selected-icon {
  filter: invert(1);
}
.osm-marker {
  @apply ft-typo-14-regular ft-leading-18 ft-flex ft-justify-center ft-items-center ft-rounded-4 ft-relative;
  @apply ft-w-fit ft-h-fit ft-min-w-[5rem] ft-min-h-[4.2rem];
}
.osm-marker:after {
  @extend .osm-marker-tail;
}
.osm-marker-selected {
  @apply ft-w-fit #{!important};
  @apply ft-h-fit #{!important};
  @apply ft-flex-col ft-bg-controls-600 ft-text-white ft-px-8 ft-py-4 ft-min-w-[9.8rem];

  > div {
    @apply ft-flex ft-self-start;
  }

  img {
    @apply ft-w-20 ft-h-20;
  }
}
.osm-marker-selected:after {
  @apply ft-border-t-controls-600 #{!important};
}
.osm-marker-unselected,
.osm-marker-unselected-assigned {
  @apply ft-bg-white ft-flex ft-flex-col ft-px-4;

  div {
    @apply ft-flex ft-self-start;
  }
}
.osm-marker-unselected:after {
  @apply ft-border-t-white #{!important};
}
.osm-marker-unselected-assigned {
  @apply ft-bg-controls-500;
}
.osm-marker-unselected-assigned:after {
  @apply ft-border-t-controls-500 #{!important};
}
.osm-courier-marker {
  background-image: url('../../../icon/src/lib/svg-icons/bike.svg');
  @apply ft-bg-white ft-p-8 ft-w-24 ft-h-24 ft-rounded-4;
  &.delivery {
    @apply ft-bg-warning-300;
  }

  &.assigned {
    @apply ft-bg-controls-500;
  }

  &.foot {
    background-image: url('../../../icon/src/lib/svg-icons/courier.svg');
  }

  &.electric_scooter,
  &.scooter {
    background-image: url('../../../icon/src/lib/svg-icons/moped.svg');
  }

  &.car {
    background-image: url('../../../icon/src/lib/svg-icons/car.svg');
  }
}
.osm-marker-popup {
  @apply ft-bottom-[0.5rem] ft-rounded-4 #{!important};

  .leaflet-popup-content-wrapper {
    @apply ft-rounded-4;
  }
  .leaflet-popup-content {
    @apply ft-m-[-0.1rem];
  }
  .leaflet-popup-close-button {
    @apply ft-text-black ft-text-20 ft-mt-[0.2rem] ft-mr-4 #{!important};
  }
}
// OSM shipment marker
.osm-marker-shipment-tooltip {
  @apply ft-typo-16-regular;
}
// OSM point marker
.osm-marker-points-container {
  @apply ft-border-0 ft-p-0;
}
.osm-marker-points {
  @apply ft-typo-16-regular ft-flex ft-justify-center ft-items-center ft-rounded-4 ft-relative;
  @apply ft-w-[5.6rem];
}
.osm-marker-points:after {
  @extend .osm-marker-tail;
  @apply ft-border-t-[inherit];
}
// compact view OSM order markers
.osm-icon-marker-container {
  @apply ft-border-0 ft-p-0;
}
.osm-marker-compact {
  @apply ft-w-8 ft-h-8 ft-bg-white ft-outline ft-outline-2 ft-outline-controls-700 ft-rounded-full;
}
.osm-marker-compact-with-route {
  @extend .osm-marker-compact;
  @apply ft-bg-controls-700;
}
.osm-marker-compact-selected {
  @extend .osm-marker-compact-selected-with-route;
}
.osm-marker-compact-selected:before {
  @apply ft-absolute ft-w-0 ft-h-0 ft-content-[''];
  @apply ft-top-[0.6rem] ft-left-[0.5rem];
  @apply ft-border-l-[0.5rem] ft-border-l-transparent;
  @apply ft-border-r-[0.5rem] ft-border-r-transparent;
  @apply ft-border-b-[0.9rem] ft-border-b-white;
}
.osm-marker-compact-selected-with-route {
  @apply ft-bg-error-500 ft-w-20 ft-relative;
  --r: 0.2rem;
  aspect-ratio: 1 / cos(30deg);
  -webkit-mask:
    conic-gradient(from -30deg at 50% calc(200% - 3 * var(--r) / 2), #000 60deg, #0000 0) 0 100%/100%
      calc(100% - 3 * var(--r) / 2) no-repeat,
    radial-gradient(var(--r) at 50% calc(2 * var(--r)), #000 98%, #0000 101%),
    radial-gradient(var(--r), #000 98%, #0000 101%) space no-repeat 0 100% / calc(2 * tan(60deg) * var(--r))
      calc(2 * var(--r));
  clip-path: polygon(50% 0, 100% 100%, 0 100%);
}
.osm-marker-compact-tooltip {
  @apply ft-w-[11rem];
  @apply ft-bg-transparent ft-mt-[-1.9rem] ft-shadow-none ft-border-none ft-p-0 ft-ml-[-0.1rem] #{!important};
}
.osm-marker-compact-tooltip-info {
  @extend .osm-marker-selected;
  @apply ft-bg-white-72 ft-text-black;

  .osm-marker-selected-icon {
    filter: invert(0);
  }
}
.osm-marker-compact-tooltip-info:after {
  @apply ft-border-t-white-72 #{!important};
}

// OSM markers styles end

.highlight-text {
  @apply ft-font-semibold;
  @apply ft-text-controls-600;
}

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: theme('colors.controls.600');
}
// for truncating sortable columns in grid
// [mat-sort-header] adds wrap to element and additional styles
// which makes impossible to add styles in component due to encapsulation
.mat-sort-header-content {
  @apply ft-truncate ft-block #{!important};
}
