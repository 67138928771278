////////////////////////////
// Silpo Text
////////////////////////////

@font-face {
  font-family: 'Silpo Text';
  src: url('../assets/fonts/SilpoText/SilpoText-Bold.eot');
  src:
    local('Silpo Text Bold'),
    local('SilpoText-Bold'),
    url('../assets/fonts/SilpoText/SilpoText-Bold.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/SilpoText/SilpoText-Bold.woff2') format('woff2'),
    url('../assets/fonts/SilpoText/SilpoText-Bold.woff') format('woff'),
    url('../assets/fonts/SilpoText/SilpoText-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Silpo Text';
  src: url('../assets/fonts/SilpoText/SilpoText-Black.eot');
  src:
    local('Silpo Text Black'),
    local('SilpoText-Black'),
    url('../assets/fonts/SilpoText/SilpoText-Black.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/SilpoText/SilpoText-Black.woff2') format('woff2'),
    url('../assets/fonts/SilpoText/SilpoText-Black.woff') format('woff'),
    url('../assets/fonts/SilpoText/SilpoText-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Silpo Text';
  src: url('../assets/fonts/SilpoText/SilpoText-Regular.eot');
  src:
    local('Silpo Text Regular'),
    local('SilpoText-Regular'),
    url('../assets/fonts/SilpoText/SilpoText-Regular.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/SilpoText/SilpoText-Regular.woff2') format('woff2'),
    url('../assets/fonts/SilpoText/SilpoText-Regular.woff') format('woff'),
    url('../assets/fonts/SilpoText/SilpoText-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Silpo Text';
  src: url('../assets/fonts/SilpoText/SilpoText-ExtraLight.eot');
  src:
    local('Silpo Text ExtraLight'),
    local('SilpoText-ExtraLight'),
    url('../assets/fonts/SilpoText/SilpoText-ExtraLight.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/SilpoText/SilpoText-ExtraLight.woff2') format('woff2'),
    url('../assets/fonts/SilpoText/SilpoText-ExtraLight.woff') format('woff'),
    url('../assets/fonts/SilpoText/SilpoText-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Silpo Text';
  src: url('../assets/fonts/SilpoText/SilpoText-Light.eot');
  src:
    local('Silpo Text Light'),
    local('SilpoText-Light'),
    url('../assets/fonts/SilpoText/SilpoText-Light.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/SilpoText/SilpoText-Light.woff2') format('woff2'),
    url('../assets/fonts/SilpoText/SilpoText-Light.woff') format('woff'),
    url('../assets/fonts/SilpoText/SilpoText-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Silpo Text';
  src: url('../assets/fonts/SilpoText/SilpoText-Thin.eot');
  src:
    local('Silpo Text Thin'),
    local('SilpoText-Thin'),
    url('../assets/fonts/SilpoText/SilpoText-Thin.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/SilpoText/SilpoText-Thin.woff2') format('woff2'),
    url('../assets/fonts/SilpoText/SilpoText-Thin.woff') format('woff'),
    url('../assets/fonts/SilpoText/SilpoText-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Silpo Text';
  src: url('../assets/fonts/SilpoText/SilpoText-SemiBold.eot');
  src:
    local('Silpo Text SemiBold'),
    local('SilpoText-SemiBold'),
    url('../assets/fonts/SilpoText/SilpoText-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/SilpoText/SilpoText-SemiBold.woff2') format('woff2'),
    url('../assets/fonts/SilpoText/SilpoText-SemiBold.woff') format('woff'),
    url('../assets/fonts/SilpoText/SilpoText-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Silpo Text';
  src: url('../assets/fonts/SilpoText/SilpoText-Medium.eot');
  src:
    local('Silpo Text Medium'),
    local('SilpoText-Medium'),
    url('../assets/fonts/SilpoText/SilpoText-Medium.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/SilpoText/SilpoText-Medium.woff2') format('woff2'),
    url('../assets/fonts/SilpoText/SilpoText-Medium.woff') format('woff'),
    url('../assets/fonts/SilpoText/SilpoText-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Silpo Text';
  src: url('../assets/fonts/SilpoText/SilpoText-ExtraBold.eot');
  src:
    local('Silpo Text ExtraBold'),
    local('SilpoText-ExtraBold'),
    url('../assets/fonts/SilpoText/SilpoText-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/SilpoText/SilpoText-ExtraBold.woff2') format('woff2'),
    url('../assets/fonts/SilpoText/SilpoText-ExtraBold.woff') format('woff'),
    url('../assets/fonts/SilpoText/SilpoText-ExtraBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
